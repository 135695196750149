/* eslint-disable func-names */
/* eslint-disable no-console */
import React from "react";
import acai from "../../assets/images/section-reservas/acai-reserva.png";
import acaiwebp from "../../assets/images/section-reservas/acai-reserva.webp";
import purpleWave from "../../assets/images/section-reservas/ondinha.svg";
import Loading from "../../assets/Icons/loading.svg";

import "./_reservations.scss";
import staticData from "./data";

const Reservations = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [booking, setBooking] = React.useState("");
  const [sending, setSending] = React.useState(false);

  const handleSubmit = (event) => {
    setSending(true);
    setTimeout(() => {
      fetch(process.env.REACT_APP_EMAIL_SERVER_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dest: "administrativo@acaiaju.com",
          sub: "Reservas do Acai Aju",
          html: `<h1 style="color:#86679c">Pedido de Reservas</h1>
                 <p style="color:#000"><b style="color: #777bd9">Cliente: </b>${name}</p>
                 <p style="color:#000"><b style="color: #777bd9">Email: </b>${email}</p>
                 <p style="color:#000"><b style="color: #777bd9">Mensagem: </b>${booking}</p>`,
        }),
      })
        .then((response) => {
          response.json();
        })
        .then(() => {
          setName("");
          setEmail("");
          setBooking("");
          // eslint-disable-next-line no-alert
          alert("Seu email foi enviado com sucesso!");
          setSending(false);
          console.log(sending);
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert(
            "Desculpe, mas parece que houve algum problema ao enviar o email."
          );
          setSending(false);
          console.log(sending);
        });
    }, 500);
    /*  */

    event.preventDefault();
  };

  return (
    <div id="reservations">
      <div className="main-container">
        <div className="left-container">
          <ul>
            <li>{staticData.events}</li>
            <li>{staticData.confraternization}</li>
            <li>{staticData.tables}</li>
          </ul>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">{staticData.name}</label>
              <input
                type="text"
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                required
                id="name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">{staticData.email}</label>
              <input
                type="email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                required
                id="email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="booking">{staticData.booking}</label>
              <textarea
                type="text"
                value={booking}
                onChange={(event) => {
                  setBooking(event.target.value);
                }}
                required
                id="booking"
              />
            </div>
            <div className="submit">
              {sending && (
                <object
                  type="image/svg+xml"
                  data={Loading}
                  alt="carregando"
                  aria-label="carregando"
                  id="loading-animation"
                />
              )}
              <button type="submit">{staticData.send}</button>
            </div>
          </form>
        </div>
        {/* <img src={acai} alt="acai" className="acai" /> */}
        <picture>
          <source type="image/webp" srcSet={acaiwebp} className="acai" />
          <img src={acai} alt="acai" className="acai" />
        </picture>
        <img src={purpleWave} alt="purple-wave" className="purple-wave" />
      </div>
    </div>
  );
};

export default Reservations;
