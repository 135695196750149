import littleWave from "../../assets/images/wave.svg";
import smartPhone from "../../assets/images/home/phone.png";
import downloadButton from "../../assets/images/home/download_icon.png";

const footerData = {
  links: {
    instagram: "https://www.instagram.com/acaiaju/?hl=pt-br",
    facebook: "https://www.facebook.com/AcaiAju/",
    whatsapp: "https://wa.me/5579996366549?text=Olá,%20meu%20amigo!",
    smartphoneApp:
      "https://play.google.com/store/apps/details?id=br.com.oneside.acaiaju",
  },
  contactsData: {
    title: "Pedido Online",
    deliveryLink: "https://deliveryapp.neemo.com.br/delivery/10187",
    phoneNumbers: [
      {
        linkNumber: "79998190758",
        number: "(79) 99819-0758",
        schedule: "09h - 15h",
      },
      {
        linkNumber: "79996366549",
        number: "(79) 99636-6549",
        schedule: "15h - 0h",
      },
      {
        linkNumber: "7935121313",
        number: "(79) 3512-1313",
        schedule: "15h - 0h",
      },
    ],
  },
  popupData: {
    icons: {
      littleWave,
      smartPhone,
      downloadButton,
    },

    texts: {
      first: "baixe agora",
      second: "nosso app!",
      button: "BAIXE AGORA",
    },
  },
};

export default footerData;
