const data = {
  events: "eventos",
  confraternization: "confraternização",
  tables: "mesas",
  name: "seu nome:",
  email: "seu email:",
  booking: "sobre a reserva:",
  send: "enviar",
};

export default data;
