import React from "react";
import "./_contacts.scss";
import Image from "../../assets/images/contact.png";
import Webp from "../../assets/images/contact.webp";
import Wave from "../../assets/images/wave.svg";
import Loading from "../../assets/Icons/loading.svg";

const Contacts = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [sending, setSending] = React.useState(false);

  /*
    {
    "dest": ["marcelviniccius@gmail.com"],
    "sub":"Assunto do email",
    "message":"aqui vc põe a mensagem, em casos que colocar o html a mensagem será substituida pelo html, dai vc poe aqui o email do cliente e o nome dele tbm.",
    "html":"`<h1>teste 2</h1>`"	
    }
   */
  const handleSubmit = (event) => {
    setSending(true);
    setTimeout(() => {
      fetch(process.env.REACT_APP_EMAIL_SERVER_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dest: "administrativo@acaiaju.com",
          sub: "Email de contato do Acai Aju",
          html: `<h1 style="color:#86679c">Pedido de Contato</h1>
               <p><b style="color: #777bd9">Cliente: </b>${name}</p>
               <p><b style="color: #777bd9">Email: </b>${email}</p>
               <p><b style="color: #777bd9">Assunto: </b>${subject}</p>
               <p><b style="color: #777bd9">Mensagem: </b>${message}</p>`,
        }),
      })
        .then((response) => {
          response.json();
        })
        .then(() => {
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
          // eslint-disable-next-line no-alert
          alert("Seu email foi enviado com sucesso!");
          setSending(false);
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          alert(
            "Desculpe, mas parece que houve algum problema ao enviar o email."
          );
          setSending(false);
        });
    }, 500);
    event.preventDefault();
  };

  return (
    <div id="contact">
      <div className="contact-screen">
        <form autoComplete="off" onSubmit={handleSubmit} className="left">
          <div className="field">seu nome:</div>
          <input
            value={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
            required
            type="text"
          />
          <div className="field">seu email:</div>
          <input
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            required
            type="email"
          />
          <div className="field">assunto:</div>
          <input
            value={subject}
            onChange={(event) => {
              setSubject(event.target.value);
            }}
            required
            type="text"
          />
          <div className="field">mensagem</div>
          <textarea
            value={message}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            required
            type="text"
          />
          <div className="button">
            <button type="submit" className="submit">
              enviar
            </button>
            {sending && (
              <object
                type="image/svg+xml"
                data={Loading}
                alt="carregando"
                aria-label="carregando"
                id="loading-animation"
              />
            )}
          </div>
        </form>
        <picture>
          <source type="image/webp" srcSet={Image} className="acai" />
          <img src={Webp} alt="acai" className="acai" />
        </picture>
        <img src={Wave} alt="purple-wave" className="purple-wave" />
      </div>
    </div>
  );
};

export default Contacts;
