import React, { useState } from "react"; //  useRef, useEffect
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "./_menu.scss";
import menuData from "./data";
import MenuModal from "../../components/menuModal/MenuModal";
import menu from "../../assets/images/menu.svg";

const Menu = () => {
  // mouse scrolling
  /*
    function useHorizontalScroll() {
      const elRef = useRef();
      useEffect(() => {
        const el = elRef.current;
        if (el) {
          const onWheel = (e) => {
            e.preventDefault();
            el.scrollTo({
              left: el.scrollLeft + e.deltaY * 7,
            });
          };
          el.addEventListener("wheel", onWheel);
          return () => el.removeEventListener("wheel", onWheel);
        }
        return elRef;
      }, []);
      return elRef;
    }
    */
  const [isModalOpen, setIsModalOpen] = useState(false);

  // drag scrolling

  document.addEventListener("DOMContentLoaded", () => {
    const slider = document.getElementById("menu-body");
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });

    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });

    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; // scroll-speed
      slider.scrollLeft = scrollLeft - walk;
    });
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(
    menuData[0].menuCategory
  );
  const [modalMainItems, setModalMainItems] = useState(null);
  const [modalSecondaryItems, setModalSecondaryItems] = useState(null);
  const [modalCategoryName, setModalCategoryName] = useState(null);
  const [modalCategoryImage, setModalCategoryImage] = useState(null);
  const [modalGourmetItems, setModalGourmetItems] = useState(null);
  const modalToggle = () => setIsModalOpen(!isModalOpen);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      <div id="menu-header">
        <div className="menu-header-container">
          <span className="header-text">VÁ PARA:</span>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret>{selectedCategory}</DropdownToggle>
            <DropdownMenu>
              {menuData.map(({ menuCategory, id }) => {
                return (
                  <DropdownItem
                    key={id}
                    href={id}
                    onClick={() => setSelectedCategory(menuCategory)}
                  >
                    {menuCategory}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <div id="menu-body">
        {menuData.map((data) => {
          if (data.type === 1) {
            return (
              <ul className={`${`id${data.id2}`} menu-inner`} id={data.id2}>
                <div className="menu-category type-1">
                  <h2 className="category-name">{data.menuCategory}</h2>
                  <div className="extra">
                    {data.mainAddItems.map((addItem) => {
                      return (
                        <div className="extra-item" key={addItem.itemTitle}>
                          <div className="item-dot" />
                          <span className="item-prefix">Adicione</span>
                          <span className="item-name-and-price">
                            <>{addItem.itemTitle}</>
                            <> + </>
                            <>{addItem.itemPrices}</>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className="all-itens"
                    onClick={() => {
                      setModalCategoryName(data.menuCategory);
                      setModalCategoryImage(data.categoryImage);
                      setModalMainItems(data.mainAddItems);
                      setModalSecondaryItems(data.secondaryAddItems);
                      setModalGourmetItems(null);
                      modalToggle();
                    }}
                    aria-hidden
                  >
                    <span>+</span>
                    <button className="open-modal" type="button">
                      Veja todos os adicionais
                    </button>
                  </div>

                  <div className="menu-principal">
                    <div className="alinhar">
                      {data.mainMenuItems.map((mainMenuItem) => {
                        return (
                          <div className="item" key={mainMenuItem.itemTitle}>
                            <span className="title">
                              {mainMenuItem.itemTitle}
                            </span>
                            <span className="prices">
                              {mainMenuItem.itemPrices}
                            </span>
                            <span className="description">
                              {mainMenuItem.itemDescription}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      className="all-itens all-main-itens"
                      onClick={() => {
                        setModalCategoryName(data.menuCategory);
                        setModalCategoryImage(data.categoryImage);
                        setModalMainItems(data.mainMenuItems);
                        setModalSecondaryItems(data.secondaryMenuItems);
                        setModalGourmetItems(null);
                        modalToggle();
                      }}
                      aria-hidden
                    >
                      <img src={menu} alt=" " />
                      <button className="open-modal" type="button">
                        Veja todos os itens
                      </button>
                    </div>
                  </div>
                </div>
                <div className="menu-image">
                  <p>{data.categoryImage.title}</p>
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={data.categoryImage.webp}
                    />
                    <img
                      src={data.categoryImage.image}
                      alt={data.categoryImage.title}
                    />
                  </picture>
                </div>
              </ul>
            );
          }
          if (data.type === 2) {
            // eslint-disable-next-line no-console
            return (
              <ul className={`${`id${data.id2}`} menu-inner`} id={data.id2}>
                <div className="menu-category type-2">
                  <h2 className="category-name">{data.menuCategory}</h2>
                  <div className="menu-principal">
                    <div className="alinhar">
                      {data.mainMenuItems.map((mainMenuItem) => {
                        return (
                          <>
                            {data.mainMenuItems.length === 1 && (
                              <div
                                className="item"
                                key={mainMenuItem.itemTitle}
                                style={{
                                  width: "200%",
                                }}
                              >
                                <span className="title">
                                  {mainMenuItem.itemTitle}
                                </span>
                                <span className="prices">
                                  {mainMenuItem.itemPrices}
                                </span>
                                {mainMenuItem.itemDescription && (
                                  <span className="description">
                                    {mainMenuItem.itemDescription}
                                  </span>
                                )}
                              </div>
                            )}
                            {data.mainMenuItems.length > 1 && (
                              <div
                                className="item"
                                key={mainMenuItem.itemTitle}
                              >
                                <span className="title">
                                  {mainMenuItem.itemTitle}
                                </span>
                                <span className="prices">
                                  {mainMenuItem.itemPrices}
                                </span>
                                {mainMenuItem.itemDescription && (
                                  <span className="description">
                                    {mainMenuItem.itemDescription}
                                  </span>
                                )}
                              </div>
                            )}
                          </>
                        );
                      })}
                      {data.gourmetItems && (
                        <div
                          className="all-itens all-main-itens gourmet-itens"
                          onClick={() => {
                            setModalCategoryName(data.menuCategory);
                            setModalCategoryImage(data.categoryImage);
                            setModalMainItems(null);
                            setModalSecondaryItems(null);
                            setModalGourmetItems(data.gourmetItems);
                            modalToggle();
                            // eslint-disable-next-line no-console
                          }}
                          aria-hidden
                        >
                          <img src={menu} alt=" " />
                          <button className="open-modal" type="button">
                            Veja as especificações
                          </button>
                        </div>
                      )}
                    </div>
                    {data.secondaryMenuItems ? (
                      <div
                        className="all-itens all-main-itens"
                        onClick={() => {
                          setModalCategoryName(data.menuCategory);
                          setModalCategoryImage(data.categoryImage);
                          setModalMainItems(data.mainMenuItems);
                          setModalSecondaryItems(data.secondaryMenuItems);
                          setModalGourmetItems(null);
                          modalToggle();
                          // eslint-disable-next-line no-console
                        }}
                        aria-hidden
                      >
                        <img src={menu} alt=" " />
                        <button className="open-modal" type="button">
                          Veja todos os itens
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="menu-image">
                  <p>{data.categoryImage.title}</p>
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={data.categoryImage.webp}
                    />
                    <img
                      src={data.categoryImage.image}
                      alt={data.categoryImage.title}
                    />
                  </picture>
                </div>
              </ul>
            );
          }
          if (data.type === 3) {
            return (
              <ul className={`${`id${data.id2}`} menu-inner`} id={data.id2}>
                <div className="menu-category">
                  <h2 className="category-name">{data.menuCategory}</h2>
                  <div className="itens-menu type-3">
                    <div className="itens-alinhados">
                      <span className="title-item">Item</span>
                      <span className="title-price">Preço (R$)</span>
                    </div>
                    {data.mainMenuItems.map((mainMenuItem) => {
                      return (
                        <div
                          className="itens-alinhados"
                          key={mainMenuItem.itemTitle}
                        >
                          <span className="item-name">
                            {mainMenuItem.itemTitle}
                          </span>
                          <span className="item-price">
                            {mainMenuItem.itemPrices}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="menu-image">
                  <p>{data.categoryImage.title}</p>
                  <picture>
                    <source
                      type="image/webp"
                      srcSet={data.categoryImage.webp}
                    />
                    <img
                      src={data.categoryImage.image}
                      alt={data.categoryImage.title}
                    />
                  </picture>
                </div>
              </ul>
            );
          }
          return 0;
        })}
        <div
          style={{
            width: "400px",
            backgroundColor: "black",
          }}
        />
      </div>
      {isModalOpen && (
        <MenuModal
          isOpen={isModalOpen}
          mainItems={modalMainItems}
          secondaryItems={modalSecondaryItems}
          gourmetItems={modalGourmetItems}
          categoryName={modalCategoryName}
          categoryImage={modalCategoryImage}
          onClose={modalToggle}
        />
      )}
    </>
  );
};

export default Menu;
