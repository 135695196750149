import React from "react";
import "./_app.scss";

import Layout from "./components/layout/Layout";

function App() {
  return (
    <>
      <Layout />
    </>
  );
}

export default App;
