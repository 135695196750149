import acai from "../../assets/images/menu/acai/Açaí-1.png";
import acaiWebp from "../../assets/images/menu/acai/Açaí-1.webp";
import paoQueijo from "../../assets/images/menu/paoDeQueijo/Pao-de-Queijo-1.png";
import paoQueijoWebp from "../../assets/images/menu/paoDeQueijo/Pao-de-Queijo-1.webp";
import lasanha from "../../assets/images/menu/Lasanhas/Lasanhas-1.png";
import lasanhaWebp from "../../assets/images/menu/Lasanhas/Lasanhas-1.webp";
import omelete from "../../assets/images/menu/omeletes/Omeletes-1.png";
import omeleteWebp from "../../assets/images/menu/omeletes/Omeletes-1.webp";
import tortaMacaxeira from "../../assets/images/menu/tortaMacaxeira/Torta-de-Macaxeira-1.png";
import tortaMacaxeiraWebp from "../../assets/images/menu/tortaMacaxeira/Torta-de-Macaxeira-1.webp";
import tapioca from "../../assets/images/menu/tapioca/Tapioca-2.png";
import tapiocaWebp from "../../assets/images/menu/tapioca/Tapioca-2.webp";
import pastel from "../../assets/images/menu/pasteis/Pastéis-1.png";
import pastelWebp from "../../assets/images/menu/pasteis/Pastéis-1.webp";
import sanduiche from "../../assets/images/menu/sanduichesNaturais/Sanduiches-Naturais-1.png";
import sanduicheWebp from "../../assets/images/menu/sanduichesNaturais/Sanduiches-Naturais-1.webp";
import hamburguerDoChef from "../../assets/images/menu/hamburguerDoChef/HamburguerDoChef-1.png";
import hamburguerDoChefWebp from "../../assets/images/menu/hamburguerDoChef/HamburguerDoChef-1.webp";
import hamburguerGourmet from "../../assets/images/menu/hamburguerGourmet/HamburguerGourmet-1.png";
import hamburguerGourmetWebp from "../../assets/images/menu/hamburguerGourmet/HamburguerGourmet-1.webp";
import miniBurguer from "../../assets/images/menu/miniBurguer/MiniBurguer-1.png";
import miniBurguerWebp from "../../assets/images/menu/miniBurguer/MiniBurguer-1.webp";
import salada from "../../assets/images/menu/salada/Salada-1.png";
import saladaWebp from "../../assets/images/menu/salada/Salada-1.webp";
import saladadefruta from "../../assets/images/menu/saladas/Salada-de-Frutas-1.png";
import saladadefrutaWebp from "../../assets/images/menu/saladas/Salada-de-Frutas-1.webp";
import suco from "../../assets/images/menu/sucos/Sucos-1.png";
import sucoWebp from "../../assets/images/menu/sucos/Sucos-1.webp";
import bebida from "../../assets/images/menu/bebidas/Bebidas-1.png";
import bebidaWebp from "../../assets/images/menu/bebidas/Bebidas-1.webp";
import cafe from "../../assets/images/menu/cafe/cafeExp.jpg";

const menuData = [
  {
    id: "#1",
    id2: "1",
    type: 1,
    menuCategory: "AÇAÍ",
    categoryImage: { title: "AÇAÍ DIPLOMATA", image: acai, webp: acaiWebp },
    mainAddItems: [
      {
        itemTitle: "RODELAS DE BANANA",
        itemPrices: "R$ 1,50",
      },
      {
        itemTitle: "GRANOLA (26G)",
        itemPrices: "R$ 2,50",
      },
      {
        itemTitle: "PEDAÇOS DE ABACAXI (60G)",
        itemPrices: "R$ 2,50",
      },
    ],
    secondaryAddItems: [
      {
        itemTitle: "MEL/LEITE CONDENSADO (50ML)",
        itemPrices: "R$ 3,00",
      },
      {
        itemTitle: "PEDAÇOS DE MORANGO/KIWI (60 G)",
        itemPrices: "R$ 3,50",
      },
      {
        itemTitle: "PEDAÇOS DE CUPUAÇU (60 G)",
        itemPrices: "R$ 3,50",
      },
      {
        itemTitle: "CHANTILY (100 ML)",
        itemPrices: "R$ 3,80",
      },
      {
        itemTitle: "CREME DE CUPUAÇU (100 ML)",
        itemPrices: "R$ 4,00",
      },
      {
        itemTitle: "CASTANHA DE CAJU (30 G)",
        itemPrices: "R$ 3,00",
      },
      {
        itemTitle: "AMENDOIM TORRADO (30 G)",
        itemPrices: "R$ 1,80",
      },
      {
        itemTitle: "PAÇOQUINHA (27 G)",
        itemPrices: "R$ 1,50",
      },
      {
        itemTitle: "CAVACO CHINÊS (20 G)",
        itemPrices: "R$ 1,50",
      },
      {
        itemTitle: "CONFEITE DE CHOCOLATE (4O G)",
        itemPrices: "R$ 2,80",
      },
      {
        itemTitle: "RASPAS DE CHOCOLATE (25 G)",
        itemPrices: "R$ 2,00",
      },
      {
        itemTitle: "LEITE EM PÓ NINHO (25 G)",
        itemPrices: "R$ 1,80",
      },
      {
        itemTitle: "FARINHA LÁCTEA NESTLÉ (25 G)",
        itemPrices: "R$ 1,80",
      },
      {
        itemTitle: "COCO RALADO (15 G)",
        itemPrices: "R$ 1,50",
      },
      {
        itemTitle: "POLPA DE FRUTA SIMPLES",
        itemPrices: "R$ 2,20",
      },
      {
        itemTitle: "POLPA DE FRUTA ESPECIAL",
        itemPrices: "R$ 3,30",
      },
      {
        itemTitle: "BOLA DE SORVETE EXTRA",
        itemPrices: "R$ 4,00",
      },
      {
        itemTitle: "NEGRESCO EM FLOCOS (15 G)",
        itemPrices: "R$ 2,00",
      },
      {
        itemTitle: "WHEY PROTEIN (30 G)",
        itemPrices: "R$ 6,80",
      },
      {
        itemTitle: "CALDA EXTRA (50 ML)",
        itemPrices: "R$ 1,20",
      },
      {
        itemTitle: "FRANGO DESFIADO (80 G)",
        itemPrices: "R$ 4,80",
      },
      {
        itemTitle: "CARNE DO SOL (100 G)",
        itemPrices: "R$ 6,80",
      },
      {
        itemTitle: "PEITO DE PERU (90 G)",
        itemPrices: "R$ 3,50",
      },
      {
        itemTitle: "QUEIJO COALHO (90 G)",
        itemPrices: "R$ 3,50",
      },
      {
        itemTitle: "QUEIJO MUSSARELA (90 G)",
        itemPrices: "R$ 3,50",
      },
      {
        itemTitle: "QUEIJO RICOTA (100 G)",
        itemPrices: "R$ 3,50",
      },
    ],
    mainMenuItems: [
      {
        itemTitle: "TRADICIONAL",
        itemPrices:
          "(P) R$ 13,80 | (M) R$ 15,80 | (G) R$ 18,80 | (1kg) R$ 36,80",
        itemDescription: "Rodelas de banana, castanha e amendoim.",
      },
      {
        itemTitle: "ZERO",
        itemPrices:
          "(P) R$ 14,50 | (M) R$ 16,50 | (G) R$ 19,50 | (1kg) R$ 37,50",
        itemDescription: "Rodelas de banana, castanha e amendoim.",
      },
      {
        itemTitle: "SERGIPANO",
        itemPrices:
          "(P) R$ 17,00 | (M) R$ 19,00 | (G) R$ 22,00 | (1kg) R$ 42,00",
        itemDescription: "Rodelas de banana, castanha e amendoim.",
      },
      {
        itemTitle: "DIPLOMATA",
        itemPrices: "(P) R$ 19,50 | (M) R$ 21,50 | (G) R$ 24,50",
        itemDescription:
          "Rodelas de banana, raspa de chocolate, chantilly e morango ou kiwi.",
      },
    ],
    secondaryMenuItems: [
      {
        itemTitle: "AÇAÍ DOCELLO",
        itemPrices: "(P) R$ 18,00 | (M) R$ 20,00 | (G) R$ 23,00",
        itemDescription:
          "Brownie Nestlé, ganache de chocolate, crocante de paçoca e rodelas de banana.",
      },
      {
        itemTitle: "AÇAÍ TROPICAL NO ABACAXI",
        itemPrices: "R$ 42,00",
        itemDescription:
          "Castanhas de caju, rodelas de banana, abacaxi e pedaços de morango ou kiwi.",
      },
      {
        itemTitle: "AÇAÍ TROPICAL",
        itemPrices: "(P) R$ 17,50 | (M) R$ 19,50 | (G) R$ 22,50",
        itemDescription:
          "Castanhas de caju, rodelas de banana, abacaxi e pedaços de morango ou kiwi.",
      },
      {
        itemTitle: "AÇAÍ BAIANINHO",
        itemPrices: "(P) R$ 15,00 | (M) R$ 17,00 | (G) R$ 21,00",
        itemDescription: "Rodelas de banana, coco ralado e raspa de chocolate",
      },
      {
        itemTitle: "AÇAÍ PAÇOQUINHA",
        itemPrices: "(P) R$ 14,50 | (M) R$ 16,50 | (G) R$ 19,50",
        itemDescription: "Rodelas de banana, paçoquinha e amendoim",
      },
      {
        itemTitle: "AÇAÍ BRINCADEIRA DE CRIANÇA",
        itemPrices: "(P) R$ 15,50 | (M) R$ 17,50 | (G) R$ 20,50",
        itemDescription: "Confeites de chocolate e rodelas de banana",
      },
      {
        itemTitle: "AÇAÍ PRIMAVERA",
        itemPrices: "(P) R$ 17,00 | (M) R$ 19,00 | (G) R$ 22,00",
        itemDescription:
          "Rodelas de banana, castanhas de caju, bola de sorvete e calda",
      },
      {
        itemTitle: "AÇAÍ EU AMO ARACAJU",
        itemPrices: "(P) R$ 17,00 | (M) R$ 19,00 | (G) R$ 22,00",
        itemDescription:
          "Castanhas de caju, rodelas de banana, raspas de chocolate branco e crocante de cavaco chinês",
      },
      {
        itemTitle: "AÇAÍ NEGRESCO",
        itemPrices: "(P) R$ 16,00 | (M) R$ 18,00 | (G) R$ 21,00",
        itemDescription:
          "Negresco em flocos, farinha láctea e rodelas de banana",
      },
      {
        itemTitle: "TAÇA CUPUÇÍ",
        itemPrices: "(P) R$ 14,50 | (M) R$ 16,50 | (G) R$ 19,50",
        itemDescription:
          "Camadas de açai tradicional e creme de cupuaçu (na taça).",
      },
      {
        itemTitle: "DELÍCIA DA TIA AUGUSTA",
        itemPrices: "R$ 28,00",
        itemDescription:
          "Taça 520ml - Camadas com pedaço de morango, leite ninho, açai, ganache de chocolate, castanha de caju e morango",
      },
      {
        itemTitle: "CREME DE CUPUAÇU NA TAÇA",
        itemPrices: "(P) R$ 15,50 | (M) R$ 17,50 | (G) R$ 20,50",
        itemDescription: "Cupuaçu batido com leite condensado",
      },
      {
        itemTitle: "MILK SHAKE DE AÇAÍ",
        itemPrices: "R$ 16,50",
        itemDescription: "Taça 520ml - Açaí batido com chantily",
      },
      {
        itemTitle: "MILK SHAKE DE SORVETE",
        itemPrices: "R$ 13,50",
        itemDescription: "Taça de 520ml",
      },
      {
        itemTitle: "TAÇA DE SORVETE C/ CALDAS",
        itemPrices: "R$ 12,00",
        itemDescription: "02 bolas de sorvete",
      },
      {
        itemTitle: "CASQUINHA DE AÇAÍ",
        itemPrices: "R$ 5,00",
      },
      {
        itemTitle: "CASCÃO DE AÇAÍ",
        itemPrices: "R$ 8,00",
      },
    ],
  },
  {
    id: "#2",
    id2: "2",
    type: 3,
    menuCategory: "PÃO DE QUEIJO MINEIRO",
    categoryImage: {
      title: "PÃO DE QUEIJO",
      image: paoQueijo,
      webp: paoQueijoWebp,
    },
    mainMenuItems: [
      {
        itemTitle: "06 UNIDADES",
        itemPrices: "R$ 10,50",
      },
      {
        itemTitle: "16 UNIDADES",
        itemPrices: "R$ 20,00",
      },
    ],
    secondaryMenuItems: null,
  },
  {
    id: "#4",
    id2: "4",
    type: 3,
    menuCategory: "LASANHAS",
    categoryImage: { title: "LASANHA", image: lasanha, webp: lasanhaWebp },
    mainMenuItems: [
      {
        itemTitle: "FRANGO",
        itemPrices: "R$ 36,00",
      },
      {
        itemTitle: "BOLONHESA",
        itemPrices: "R$ 44,00",
      },
      {
        itemTitle: "MISTA (FRANGO + BOLONHESA)",
        itemPrices: "R$ 44,00",
      },
      {
        itemTitle: "CAMARÃO",
        itemPrices: "R$ 58,00",
      },
    ],
    secondaryMenuItems: null,
  },
  {
    id: "#5",
    id2: "5",
    menuCategory: "OMELETES",
    type: 3,
    categoryImage: { title: "OMELETE", image: omelete, webp: omeleteWebp },
    subtitle:
      "Ovos delicadamente batidos com toques de salsa fresca com um dos recheios abaixo:",
    mainMenuItems: [
      {
        itemTitle: "OVOS MEXIDOS SIMPLES",
        itemPrices: "R$ 9,00",
      },
      {
        itemTitle: "OVOS MEXIDOS C/ QUEIJO",
        itemPrices: "R$ 11,00",
      },
      {
        itemTitle: "OVOS MEXIDOS C/ PEITO DE PERU",
        itemPrices: "R$ 13,50",
      },
      {
        itemTitle: "OMELETE DE FRANGO CAIPIRA",
        itemPrices: "R$ 25,00",
      },
      {
        itemTitle: "AÇAÍ AJU",
        itemPrices: "R$ 34,00",
        itemDescription: "Recheio cremoso de queijo e camarões selecionados.",
      },
      {
        itemTitle: "VIRGULINO",
        itemPrices: "R$ 27,00",
        itemDescription:
          "Recheio cremoso de carne do sol puxados na cebola e manteiga",
      },
      {
        itemTitle: "BEM-ESTAR",
        itemPrices: "R$ 22,00",
        itemDescription: "Recheio cremoso com queijo e peito de peru",
      },
      {
        itemTitle: "MANGUE SECO",
        itemPrices: "R$ 34,00",
        itemDescription: "Recheio de aratu refogado no azeite",
      },
      {
        itemTitle: "MÃE TERRA",
        itemPrices: "R$ 20,00",
        itemDescription:
          "Recheio de queijo e legumes puxados e manteiga e especiarias",
      },
    ],
    secondaryMenuItems: null,
  },
  {
    id: "#6",
    id2: "6",
    type: 2,
    menuCategory: "TORTA DE MACAXEIRA",
    categoryImage: {
      title: "TORTA DE MACAXEIRA",
      image: tortaMacaxeira,
      webp: tortaMacaxeiraWebp,
    },
    subtitle: "Torta de macaxeira gratinada",
    mainMenuItems: [
      {
        itemTitle: "CARNE MOÍDA",
        itemPrices: "R$ 34,00 (2 pessoas)",
      },
      {
        itemTitle: "FRANGO",
        itemPrices: "R$ 37,00 (2 pessoas)",
      },
      {
        itemTitle: "CHARQUE",
        itemPrices: "R$ 49,00 (2 pessoas)",
      },
      {
        itemTitle: "CARNE DO SOL",
        itemPrices: "R$ 39,00 (2 pessoas)",
      },
    ],
    secondaryMenuItems: [
      {
        itemTitle: "CAMARÃO",
        itemPrices: "R$ 49,00 (2 pessoas)",
      },
      {
        itemTitle: "ARATU",
        itemPrices: "R$ 49,00 (2 pessoas)",
      },
      {
        itemTitle: "MISTA (EXCETO CAMARÃO, CHARQUE E ARATU)",
        itemPrices: "R$ 39,00 (2 pessoas)",
      },
    ],
  },
  {
    id: "#7",
    id2: "7",
    type: 2,
    menuCategory: "BEIJU DE TAPIOCA",
    categoryImage: {
      title: "BEIJU DE TAPIOCA",
      image: tapioca,
      webp: tapiocaWebp,
    },
    mainMenuItems: [
      {
        itemTitle: "MANTEIGA",
        itemPrices: "R$ 12,00",
      },
      {
        itemTitle: "QUEIJO COALHO",
        itemPrices: "R$ 16,50",
      },
      {
        itemTitle: "CARNE COM ERVILHA",
        itemPrices: "R$ 19,50",
      },
      {
        itemTitle: "FRANGO COM CATUPIRY",
        itemPrices: "R$ 20,00",
      },
    ],
    secondaryMenuItems: [
      {
        itemTitle: "ATUM COM CATUPIRY",
        itemPrices: "R$ 20,00",
      },
      {
        itemTitle: "PEITO DE PERU COM CATUPIRY",
        itemPrices: "R$ 20,00",
      },
      {
        itemTitle: "CHARQUE COM CATUPIRY",
        itemPrices: "R$ 28,00",
      },
      {
        itemTitle: "CARNE DE SOL COM QUEIJO COALHO",
        itemPrices: "R$ 25,00",
      },
      {
        itemTitle: "QUEIJO COM GOIABADA",
        itemPrices: "RS 19,50",
      },
      {
        itemTitle: "CAMARÃO COM CATUPIRY",
        itemPrices: "R$ 28,00",
      },
      {
        itemTitle: "QUEIJO COALHO, BANANA, CANELA E AÇÚCAR",
        itemPrices: "R$ 19,50",
      },
      {
        itemTitle: "QUEIJO, COCO RALADO E LEITE CONDENSADO",
        itemPrices: "R$ 19,50",
      },
    ],
  },
  {
    id: "#8",
    id2: "8",
    type: 3,
    menuCategory: "PASTÉIS",
    categoryImage: {
      title: "PASTEL DE CAMARÃO",
      image: pastel,
      webp: pastelWebp,
    },
    mainMenuItems: [
      {
        itemTitle: "QUEIJO COALHO",
        itemPrices: "R$ 9,50",
      },
      {
        itemTitle: "FRANGO COM CATUPIRY",
        itemPrices: "R$ 10,50",
      },
      {
        itemTitle: "CARNE COM ERVILHA",
        itemPrices: "R$ 10,50",
      },
      {
        itemTitle: "CHARQUE COM CATUPIRY",
        itemPrices: "R$ 15,80",
      },
      {
        itemTitle: "ARATU",
        itemPrices: "R$ 15,80",
      },
      {
        itemTitle: "PIZZA",
        itemPrices: "R$ 10,50",
      },
      {
        itemTitle: "QUEIJO COALHO, BANANA, CANELA E AÇÚCAR",
        itemPrices: "R$ 10,50",
      },
      {
        itemTitle: "CAMARÃO",
        itemPrices: "R$ 16,00",
      },
      {
        itemTitle: "QUEIJO COM GOIABADA",
        itemPrices: "R$ 10,00",
      },
    ],
    secondaryMenuItems: null,
  },
  {
    id: "#9",
    id2: "9",
    type: 3,
    menuCategory: "SANDUÍCHES NATURAIS",
    categoryImage: {
      title: "FRANGO DESFIADO",
      image: sanduiche,
      webp: sanduicheWebp,
    },
    mainMenuItems: [
      {
        itemTitle: "RICOTA",
        itemPrices: "R$ 14,00",
        itemDescription: "Ricota com passas, peito de peru e alface",
      },
      {
        itemTitle: "ATUM",
        itemPrices: "R$ 14,00",
        itemDescription: "Atum com salsa, cenoura e requeijão cremoso",
      },
      {
        itemTitle: "PEITO DE PERU",
        itemPrices: "R$ 14,00",
        itemDescription: "Peito de peru, ricota e geleia de ameixa",
      },
      {
        itemTitle: "FRANGO DESFIADO",
        itemPrices: "R$ 14,00",
        itemDescription:
          "Frango Desfiado, milho, cenoura, requeijão cremoso e alface",
      },
      {
        itemTitle: "SANDUÍCHE CASEIRO",
        itemPrices: "R$ 9,50",
        itemDescription: "Pão, queijo coalho, tomate e alface",
      },
    ],
    secondaryMenuItems: null,
  },
  {
    id: "#10",
    id2: "10",
    type: 2,
    menuCategory: "HAMBURGUER DO CHEF",
    categoryImage: {
      title: "HAMBURUGER DO CHEF",
      image: hamburguerDoChef,
      webp: hamburguerDoChefWebp,
    },
    mainMenuItems: [
      {
        itemTitle: "CLÁSSICO MAGISTRAL",
        itemPrices: "R$ 27,50",
        itemDescription:
          "Especial burger de carne magra com molho italiano de tomate e muçarela",
      },
      {
        itemTitle: "FENOMENAL DE PICANHA",
        itemPrices: "R$ 29,50",
        itemDescription:
          "Maravilhoso burger de picanha com provolone, rúcula e cebola caramelizada com shoyu",
      },
      {
        itemTitle: "COLOSSAL DE FRANGO",
        itemPrices: "R$ 26,00",
        itemDescription:
          "Burger de frango empanado levemente temperado com especiarias, cream cheese, alface americano e maionese do chef",
      },
    ],
  },
  {
    id: "#11",
    id2: "11",
    type: 2,
    menuCategory: "HAMBURGUER GOURMET",
    categoryImage: {
      title: "HAMBURGUER GOURMET",
      image: hamburguerGourmet,
      webp: hamburguerGourmetWebp,
    },
    mainMenuItems: [
      {
        itemTitle: "CLÁSSICO",
        itemPrices: "R$ 32,50",
        itemDescription: "Carne magra temperada com especiarias",
      },
      {
        itemTitle: "PICANHA",
        itemPrices: "R$ 34,50",
        itemDescription: "Carne de picanha levemente temperada",
      },
      {
        itemTitle: "FRANGO",
        itemPrices: "R$ 31,00",
        itemDescription:
          "Carne de peito de frango levemente processada com temperos regionais",
      },
    ],
    secondaryMenuItems: null,
    gourmetItems: {
      gourmetTitle: "ESCOLHA O QUEIJO E DOIS MOLHOS",
      allGourmetItems: [
        {
          itemGourmetTitle: "MOLHO",
          itemsGourmet: [
            {
              itemGourmetName: "MAIONESE DA MAMA",
              itemGourmetDescription:
                "Maionese caseira com toque de limão e temperos verdes",
            },
            {
              itemGourmetName: "PESTO DE RÚCULA",
              itemGourmetDescription:
                "Azeite de oliva, folhas de rúcula, parmesão e castanhas de caju",
            },
            {
              itemGourmetName: "MOLHO DE QUEIJO",
              itemGourmetDescription:
                "Creme caseiro de parmesão, catupiry e creme de leite",
            },
            {
              itemGourmetName: "MOSTARDA AO MEL",
              itemGourmetDescription: "Combinação de dijon com mel de acácias",
            },
            {
              itemGourmetName: "MOLHO BARBECUE",
            },
          ],
        },
        {
          itemGourmetTitle: "QUEIJO",
          itemsGourmet: [
            {
              itemGourmetName: "MUÇARELA",
            },
            {
              itemGourmetName: "PRATO",
            },
            {
              itemGourmetName: "CHEDDAR",
            },
            {
              itemGourmetName: "COALHO",
            },
            {
              itemGourmetName: "CREAM CHEESE",
            },
            {
              itemGourmetName: "GORGONZOLA",
            },
          ],
        },
        {
          itemGourmetTitle: "PORÇÃO EXTRA DE MOLHO",
          itemGourmetPrices: "R$ 3,00",
        },
        {
          itemGourmetTitle: "PORÇÃO EXTRA DE BATATAS DO CHEF",
          itemGourmetPrices: "R$ 6,00",
        },
      ],
    },
  },
  {
    id: "#12",
    id2: "12",
    type: 2,
    menuCategory: "MINI BURGUERS",
    categoryImage: {
      title: "MINI BURGUER",
      image: miniBurguer,
      webp: miniBurguerWebp,
    },
    mainMenuItems: [
      {
        itemTitle: "MINI DE PICANHA",
        itemPrices: "R$ 29,50",
        itemDescription:
          "Hamburguer de picanha, molho barbecue, queijo prato e crisps de cebola. Acompanha cebola empanada",
      },
      {
        itemTitle: "MINI DE FRANGO",
        itemPrices: "R$ 26,00",
        itemDescription:
          "Suculento peito de frango empanado, maionese do chef, cream cheese e alface americana. Acompanha batata do chef",
      },
    ],
    secondaryMenuItems: null,
  },
  {
    id: "#13",
    id2: "13",
    type: 2,
    menuCategory: "SALADA",
    categoryImage: {
      title: "SALADA",
      image: salada,
      webp: saladaWebp,
    },
    mainMenuItems: [
      {
        itemTitle: "SALADA BEIRA MAR",
        itemPrices: "R$ 25,00",
        itemDescription:
          "Alface, rúcula, repolho, cenoura ralada, azeitonas e pedaços de ricota.",
      },
    ],
  },
  {
    id: "#14",
    id2: "14",
    type: 2,
    menuCategory: "SALADA DE FRUTAS",
    categoryImage: {
      title: "SALADA DE FRUTAS",
      image: saladadefruta,
      webp: saladadefrutaWebp,
    },
    mainMenuItems: [
      {
        itemTitle: "SALADA DE FRUTAS",
        itemPrices: "(P) R$ 10,00 | (M) R$ 11,00 | (G) R$ 12,00",
      },
      {
        itemTitle: "SALADA DE FRUTAS COM SORVETE",
        itemPrices: "(P) R$ 12,50 | (M) R$ 13,50 | (G) R$ 14,50",
      },
      {
        itemTitle: "SALADA DE FRUTAS COM AÇAÍ",
        itemPrices: "(P) R$ 16,50 | (M) R$ 18,50 | (G) R$ 22,00",
      },
    ],
    secondaryMenuItems: null,
  },
  {
    id: "#15",
    id2: "15",
    type: 3,
    menuCategory: "SUCOS",
    categoryImage: { title: "SUCO DE MORANGO", image: suco, webp: sucoWebp },
    subtitle: "(520 ML)",
    mainMenuItems: [
      {
        itemTitle: "AÇAÍ",
        itemPrices: "R$ 12,50",
      },
      {
        itemTitle: "CAPUAÇU OU MORANGO",
        itemPrices: "R$ 12,00",
      },
      {
        itemTitle: "LARANJA, LIMÃO OU ABACAXI (FRUTA)",
        itemPrices: "R$ 10,50",
      },
      {
        itemTitle: "SUCOS DE POLPA",
        itemPrices: "R$ 9,50",
        itemDescription:
          "Mangaba, Graviola, Goiaba, Caju, Acerola, Umbu, Ameixa, Cajá ou Maracujá",
      },
      {
        itemTitle: "CLOROFILA",
        itemPrices: "R$ 10,00",
      },
      {
        itemTitle: "MARACUJÁ COM CAPIM-SANTO",
        itemPrices: "R$ 11,00",
      },
      {
        itemTitle: "ABACAXI OU LARANJA COM HORTELÃ",
        itemPrices: "R$ 11,00",
      },
      {
        itemTitle: "MAMÃO COM LARANJA",
        itemPrices: "R$ 11,50",
        itemDescription: "Suco da fruta",
      },
      {
        itemTitle: "LARANJA COM CENOURA",
        itemPrices: "R$ 11,00",
      },
      {
        itemTitle: "DETOX 1",
        itemPrices: "R$ 12,50",
        itemDescription: "Laranja, Cenoura e Gengibre",
      },
      {
        itemTitle: "DETOX 2",
        itemPrices: "R$ 12,50",
        itemDescription: "Abacaxi ou Laranja com Gengibre e Hortelã",
      },
      {
        itemTitle: "TURBINADO 1",
        itemPrices: "R$ 14,50",
        itemDescription: "Açaí, Castanha e Amendoim",
      },
      {
        itemTitle: "TURBINADO 2",
        itemPrices: "R$ 15,50",
        itemDescription: "Açaí, Polpa de fruta, Amendoim e Hortelã",
      },
    ],
    secondaryMenuItems: null,
  },
  {
    id: "#16",
    id2: "16",
    type: 3,
    menuCategory: "BEBIDAS",
    categoryImage: { title: "BEBIDAS", image: bebida, webp: bebidaWebp },
    mainMenuItems: [
      {
        itemTitle: "REFRIGERANTE LATA 350 ML",
        itemPrices: "R$ 5,00",
      },
      {
        itemTitle: "SKOL LATA 350 ML",
        itemPrices: "R$ 5,00",
      },
      {
        itemTitle: "CERVEJA GORÉ PILSEN 275ML - UÇÁ",
        itemPrices: "R$ 8,50",
      },
      {
        itemTitle: "HEINIKEN LONG NECK",
        itemPrices: "R$ 8,50",
      },
      {
        itemTitle: "ÁGUA MINERAL GARRAFA 500 ML",
        itemPrices: "R$ 3,50",
      },
      {
        itemTitle: "ÁGUA MINERAL COM GÁS GARRAFA 500 ML",
        itemPrices: "R$ 4,00",
      },
      {
        itemTitle: "CHOCOLATE QUENTE (CANECA)",
        itemPrices: "R$ 6,00",
      },
      {
        itemTitle: "CHOCOLATE QUENTE COM CHANTILY (CANECA)",
        itemPrices: "R$ 7,80",
      },
      {
        itemTitle: "CHOCOLATE GELADO (TAÇA 520 ML)",
        itemPrices: "R$ 7,80",
      },
      {
        itemTitle: "CAFÉ COADO (XÍCARA P)",
        itemPrices: "R$ 3,00",
      },
      {
        itemTitle: "CAFÉ COADO (XÍCARA G)",
        itemPrices: "R$ 4,50",
      },
      {
        itemTitle: "CAFÉ COADO COM LEITE (XÍCARA G)",
        itemPrices: "R$ 5,50",
      },
    ],
    secondaryMenuItems: null,
  },
  {
    id: "#17",
    id2: "17",
    type: 3,
    menuCategory: "CAFÉ EXPRESSO",
    categoryImage: { title: "CAFÉ EXPRESSO", image: cafe, webp: cafe },
    mainMenuItems: [
      {
        itemTitle: "LEGGERO 40 ML",
        itemPrices: "R$ 7,00",
      },
      {
        itemTitle: "FORTE 40 ML",
        itemPrices: "R$ 7,00",
      },
      {
        itemTitle: "LUNGO LEGGERO 110ML",
        itemPrices: "R$ 7,50",
      },
      {
        itemTitle: "DESCAFEINADO 40ML",
        itemPrices: "R$ 7,00",
      },
      {
        itemTitle: "RISTRETO 25ML",
        itemPrices: "R$ 6,50",
      },
      {
        itemTitle: "CAPPUCCINO 140ML",
        itemPrices: "R$ 9,50",
      },
      {
        itemTitle: "MACHIATTO 50ML",
        itemPrices: "R$ 8,00",
      },
      {
        itemTitle: "CARAMELO MACHIATTO 300ML",
        itemPrices: "R$ 11,00",
      },
      {
        itemTitle: "CAFÉ LATTE 140ML",
        itemPrices: "R$ 9,50",
      },
    ],
    secondaryMenuItems: null,
  },
];

export default menuData;
