import React, { useState } from "react";

import "./_footer.scss";

import footerData from "./data";

const Footer = () => {
  // const [showPopup, setShowPopup] = useState(true);
  const [deliveryContacts, setDeliveryContacts] = useState(false);

  const handleClick = () => {
    setDeliveryContacts(!deliveryContacts);
  };

  /* function pathname() {
    if (window.location.pathname === "/") return true;
    return false;
  } */

  return (
    <div className="footer-container">
      <div className="waves" />
      <div className="footer-items">
        <a
          href={footerData.links.whatsapp}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div id="whatsapp" />
        </a>
        <a
          href={footerData.links.instagram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div id="instagram" />
        </a>
        <a
          href={footerData.links.facebook}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div id="facebook" />
        </a>

        <div id="contact-buttom">
          <a href="/contacts">contato</a>
        </div>
        <div id="div-bar" />
        <div>
          {deliveryContacts ? (
            <div className="delivery-contacts">
              <div className="contacts-header">
                <h5>{footerData.contactsData.title}</h5>
                <a href={footerData.contactsData.deliveryLink}>Clique aqui</a>
              </div>
              <div className="contacts-content">
                {footerData.contactsData.phoneNumbers.map(
                  ({ linkNumber, number, schedule }, i) => {
                    return (
                      <div
                        key={linkNumber + number + schedule + i.toString()}
                        className="contact-number"
                      >
                        <a href={`tel:+550${linkNumber}`}>{number}</a>
                        <div className="schedule">{schedule}</div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          ) : null}

          <button type="button" onClick={handleClick}>
            <div id="delivery" />
          </button>
        </div>
      </div>
      {/* pathname() ? (
        <div className="download-box">
          {showPopup ? (
            <div className="download-popup">
              <button
                type="button"
                className="close"
                onClick={() => {
                  setShowPopup(false);
                }}
              >
                X
              </button>
              <div className="middle">
                <div className="text">
                  <img
                    src={footerData.popupData.icons.littleWave}
                    alt="Ondinha"
                  />
                  <div className="black">
                    {footerData.popupData.texts.first}
                  </div>
                  <div className="bold">
                    {footerData.popupData.texts.second}
                  </div>
                  <img
                    src={footerData.popupData.icons.littleWave}
                    alt="Ondinha"
                  />
                </div>
                <div className="image">
                  <img
                    src={footerData.popupData.icons.smartPhone}
                    alt="telefone"
                  />
                </div>
              </div>
              <a
                href={footerData.links.smartphoneApp}
                target="_blank"
                rel="noopener noreferrer"
                className="button"
              >
                <img
                  src={footerData.popupData.icons.downloadButton}
                  alt="download_icon"
                />
              </a>
            </div>
          ) : (
            <></>
          )}
          <button
            type="button"
            className="download"
            onClick={() => {
              setShowPopup(true);
            }}
          >
            {footerData.popupData.texts.button}
          </button>
        </div>
      ) : (
        <></>
      ) */}
    </div>
  );
};

export default Footer;
