import React from "react";
import PropTypes from "prop-types";

// Components
import { NavItem, NavLink } from "reactstrap";

// Styles
import "./_sidebarItem.scss";

// General Data
import sideBarData from "../data";

const Sidebar = ({ id, link, Icon, imgAlt, text }) => {
  // Destructured Data
  const { menuData } = sideBarData;

  return (
    <NavItem className={id === 1 ? "menu-start" : ""}>
      <NavLink href={link} className={id === 4 ? "menu-end" : ""}>
        <div className="mobile-link-style">
          <div className="desktop-link-style">
            <div className="link-img-container">
              <img src={Icon} alt={imgAlt} style={{ maxWidth: "44px" }} />
            </div>

            <span className="link-text-style">{text}</span>
          </div>

          <img
            src={menuData.arrowIcon}
            alt="Icone de flecha"
            className="d-md-none link-arrow-style"
          />
        </div>
      </NavLink>
    </NavItem>
  );
};

Sidebar.propTypes = {
  id: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  Icon: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Sidebar;
